import React, { useState } from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";

const ContactPage = ({ data: { site }, location }) => {
  const extraSubjectProps = {};
  if (location.state && location.state.projekt) {
    extraSubjectProps.value = `Projektanfrage ${location.state.projekt}`;
  }

  const [submitted, setSubmitted] = useState({
    submitted: false,
  });

  const handleInvalid = (event) => {
    // Show what the required fields are. Design choice because with
    // so few fields, it's generally obvious
    setSubmitted((prevState) => ({ ...prevState, submitted: true }));
  };

  return (
    <Layout>
      <Helmet>
        <title>Kontakt — {site.siteMetadata.title}</title>
        <meta
          name="description"
          content={"Contact page of " + site.siteMetadata.description}
        />
      </Helmet>
      <div className="contact-container">
        {extraSubjectProps.value ? (
          <h2>Super! Einfach kurz schreiben</h2>
        ) : (
          <React.Fragment>
            <h2>Einfach anrufen</h2>
            <div>
              <a
                href="tel://00492122239800"
                className="social-link"
                style={{ marginTop: 0 }}
              >
                <img src={"/phone.svg"}></img> 0212 / 22 39 800
              </a>
            </div>
            <h2>oder kurz schreiben</h2>
          </React.Fragment>
        )}
        <form
          className={`form-container${submitted.submitted ? " submitted" : ""}`}
          // action="https://sendmail.w3layouts.com/SubmitContactForm"
          action="/danke"
          name="Kontaktformular"
          method="post"
          onInvalid={handleInvalid}
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="Kontaktformular" />
          <div>
            <label htmlFor="name">
              Name<span className="validationRemark">*</span>
            </label>
            <input required type="text" name="name" id="name" />
          </div>
          <div>
            <label htmlFor="sender">
              E-Mail<span className="validationRemark">*</span>
            </label>
            <input required type="email" name="sender" id="sender" />
          </div>
          <div>
            <label htmlFor="subject">Betreff</label>
            <input
              type="text"
              name="subject"
              id="subject"
              {...extraSubjectProps}
            />
          </div>
          <div>
            <label htmlFor="message">
              Nachricht<span className="validationRemark">*</span>
            </label>
            <textarea required name="message" id="message"></textarea>
          </div>
          <div className="validationRemark">
            <span className="validationRemark">*</span>Bitte ausfüllen
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <input
              type="submit"
              className="button -primary"
              style={{ marginRight: 0 }}
              value="Abschicken"
            />
          </div>
        </form>
        <br />

        {extraSubjectProps.value ? (
          <React.Fragment>
            <h2>..oder kurz anrufen?</h2>
            <div>
              <a
                href="tel://00492122239800"
                className="social-link"
                style={{ marginTop: 0 }}
              >
                <img src={"/phone.svg"}></img> 0212 / 22 39 800
              </a>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
        <div>
          <iframe
            frameBorder="0"
            style={{
              border: 0,
              width: "70vw",
              height: "450px",
              marginBottom: "10px",
            }}
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAO-Gro6J8fldEpaZIk35Dzv_5uO5-ZPz0
    &q=WoodLooksGood,Solingen"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </Layout>
  );
};
export default ContactPage;
export const pageQuery = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
